import { createSlice } from '@reduxjs/toolkit';
import { config_options } from "./dashboardConfigsSlice";

const initialDashboardFiltersState = {
  filters: {
    /*
      tableA: {
	      fieldNameA: { target, filterType }
	      displayConfig__configUuidA: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
      },
      tableB: {
	      ...
      }
    */
  },
  filtersInputs: {
    /*
      <objectName>: {
        value:
      }
    */
  },
  displayConfigsToggleState: {
    hide_row: true,
    suppress_alert: true
  },
  loading: false,
}

export const displayConfigTogglePayload = (stateToToggle, newValue) => {
  return {
    stateToToggle: stateToToggle,
    newValue: newValue
  }
}

export const displayConfigFilterPayload = (configUuid, displayConfigTableRows, sourceTable) => {
  return {
    configUuid: configUuid,
    displayConfigTableRows: displayConfigTableRows,
    sourceTable: sourceTable
  }
}

export const defaultFilterPayload = (objectName, field, target, filterType, mainTable, additionalTables) => {
  return {
    objectName: objectName,
    field: field,
    target: target,
    filterType: filterType,
    mainTable: mainTable,
    additionalTables: additionalTables ?? []
  }
}

// displayConfig__configUuid__rowUuid: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
const deleteDisplayConfigFilters = (configUuid, filters) => {
  // console.log("start deleteDisplayConfigFilters")
  // console.log("configUuid ", configUuid);
  const filtersClone = {...filters};
  const tables = Object.keys(filters);
  // console.log("tables ", tables)
  for (let i = 0; i < tables.length; i++) {
    const tableFilters = Object.keys(filters[tables[i]]);
    // console.log("tableFilters ", tableFilters)
    const filtersForThisConfig = tableFilters.filter((key) => key.includes(`displayConfig__${configUuid}`));
    // console.log("filtersForThisConfig ", filtersForThisConfig)
    if (filtersForThisConfig.length > 0) {
      filtersForThisConfig.forEach((filter) => {
        // console.log("deleting ->", filter, filtersClone[tables[i]][filter]);
        delete filtersClone[tables[i]][filter];
      })
    }
  }
  // console.log("filtersClone", filtersClone);
  // console.log("end deleteDisplayConfigFilters")
  return filtersClone
}

// displayConfig__configUuid__rowUuid: { valueMap: {...}, displayConfigOptions: { hide_row: true } }
const addDisplayConfigFilters = (configUuid, filters, sourceTable, displayConfigTableRows) => {
  const filtersClone = JSON.parse(JSON.stringify(filters));
  // you need to go row by row
  displayConfigTableRows.forEach((displayConfigTableRow) => {
    /*
    ::: displayConfigRow :::
    String uuid;                // this is constant
    String companyUuid;
    String createdBy;
    Map<String, Object> primaryKeyValueMap;
    Map<String, Map<String, Object>> linkedTableForeignValueMaps;
    Map<String, Object> displayConfigOptions;
    */
    const { uuid, primaryKeyValueMap, displayConfigOptions, linkedTableForeignValueMaps } = displayConfigTableRow;
    filtersClone[sourceTable][`displayConfig__${configUuid}__${uuid}`] = { valueMap: primaryKeyValueMap, displayConfigOptions: displayConfigOptions }
    if (linkedTableForeignValueMaps) {
      const linkedTables = Object.keys(linkedTableForeignValueMaps)
      if (linkedTables.length > 0) {
        linkedTables.forEach(linkedTable => {
          if (filtersClone[linkedTable]) {
            filtersClone[linkedTable][`displayConfig__${configUuid}__${uuid}`] = { valueMap: linkedTableForeignValueMaps[linkedTable], displayConfigOptions: displayConfigOptions }
          }
        })
      }
    }
  })
  return filtersClone;
}


export const dashboardFiltersSlice = createSlice({
  name: "dashboardFilters",
  initialState: initialDashboardFiltersState,
  reducers: {
    // sets up a filter dict for each table
    setUpFilters: (state, action) => {
      console.log("dashboardFiltersSlice - setUpFilters:", action.payload);
      const { tables } = action.payload;
      const tableNames = Object.keys(tables);
      const newFilters = {};
      tableNames.forEach(tableName => {
        newFilters[tableName] = {}
      })
      state.filters = newFilters;
    },
    // one for filter components (objectName, defaultFilterPayload) -> sets filters and sets value in filterInputs for passed objectName
    // each filter component (search, select, radio) has useEffect logic to dispatch setFilters - this populates their initial input value in state.filtersInputs
    setFilters: (state, action) => {
      // console.log("setFilters", action.payload)
      const {objectName, field, target, filterType, mainTable, additionalTables} = action.payload;
      const filtersClone = JSON.parse(JSON.stringify(state.filters));
      // console.log("filtersClone pre processing:", filtersClone)
      let all_tables = [mainTable];
      if (additionalTables) all_tables = all_tables.concat(additionalTables)
      for (let i = 0; i < all_tables.length; i++) {
        if (
          (target === "All" &&
            (filterType === "regular" ||
              filterType === "year" ||
              filterType === "month")) ||
          (target === "" && filterType === "search")
        ) {
          delete filtersClone[all_tables[i]][field];
        } else {
          filtersClone[all_tables[i]][field] = { target: target, filterType: filterType };
        }
      }
      const inputsClone = JSON.parse(JSON.stringify(state.filtersInputs))
      inputsClone[objectName] = {};
      inputsClone[objectName]["value"] = target;
      console.log("filters", filtersClone)
      console.log("filtersInputs", inputsClone)
      state.filters = filtersClone;
      state.filtersInputs = inputsClone;
      state.loading = true;
    },
    setMultipleFilters: (state, action) => {
      console.log("setMultipleFilters", action.payload);
      const filtersClone = JSON.parse(JSON.stringify(state.filters));
      const inputsClone = JSON.parse(JSON.stringify(state.filtersInputs));
      action.payload.forEach(filter => {
        const { objectName, field, target, filterType, mainTable, additionalTables } = filter;
        let all_tables = [mainTable];
        if (additionalTables) all_tables = all_tables.concat(additionalTables)
        for (let i = 0; i < all_tables.length; i++) {
          if (
            (target === "All" &&
              (filterType === "regular" ||
                filterType === "year" ||
                filterType === "month")) ||
            (target === "" && filterType === "search")
          ) {
            delete filtersClone[all_tables[i]][field];
          } else {
            filtersClone[all_tables[i]][field] = { target: target, filterType: filterType };
          }
        }
        inputsClone[objectName] = {};
        inputsClone[objectName]["value"] = target;
      })
      state.filters = filtersClone;
      state.filtersInputs = inputsClone;
      state.loading = true;
    },
    setDisplayConfigFilters: (state, action) => {
      // console.log("setDisplayConfigFilters", action.payload);
      const { configUuid, displayConfigTableRows, sourceTable } = action.payload;
      const currentFilters = JSON.parse(JSON.stringify(state.filters));
      // console.log("current filters", currentFilters);
      const cleanedFilters = deleteDisplayConfigFilters(configUuid, currentFilters);
      // console.log("cleanedFilters", cleanedFilters);
      const updatedFilters = addDisplayConfigFilters(configUuid, cleanedFilters, sourceTable, displayConfigTableRows);
      console.log("filters", updatedFilters);
      state.filters = updatedFilters;
      state.loading = true;
    },
    setDisplayConfigsToggleState: (state, action) => {
      // console.log("setDisplayConfigsToggleState", action.payload);
      const { stateToToggle, newValue } = action.payload;
      state.displayConfigsToggleState[stateToToggle] = newValue;
      if (stateToToggle === config_options.hide_row) {
        state.loading = true;
      }
    },
    setLoading: (state, action) => {
      // console.log("dashboardFiltersSlice - setLoading", action.payload);
      state.loading = action.payload;
    },
    resetDashboardFiltersSlice: (state, action) => {
      console.log("dashboardFiltersSlice - resetDashboardFilters")
      return initialDashboardFiltersState;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setUpFilters,
  setFilters,
  setMultipleFilters,
  setDisplayConfigFilters,
  setDisplayConfigsToggleState,
  setLoading,
  resetDashboardFiltersSlice
} = dashboardFiltersSlice.actions;

export default dashboardFiltersSlice.reducer;