import React, {useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { setFilters, defaultFilterPayload } from "../../../store/dashboardFiltersSlice";
import { useDispatch, useSelector } from "react-redux";

/**
 * FDSearchV2 is a component that allows the user to search for a value in a table.
 * It is used in the FDRecipeTable component.
 * @param {Object} props - The props object.
 * @returns {JSX.Element} - A component that allows the user to search for a value in a table.
 */
function FDSearchV2(props) {
  const dispatch = useDispatch();
  let field = props.field;

  useEffect(() => {
    if(props.flagFilterComponentName !== undefined && props.flagFilterInput !== undefined) {
      dispatch(setFilters(defaultFilterPayload(props.objectName, field, props.flagFilterInput, "search", props.sourceTable, props.additionalTables)))
    } else {
      dispatch(setFilters(defaultFilterPayload(props.objectName, field, "", "search", props.sourceTable, props.additionalTables)))
    }
  }, [props.flagFilterComponentName, props.flagFilterInput]);

  const [inputValue, setInputValue] = useState((props.flagFilterComponentName !== undefined && props.flagFilterInput !== undefined) ? props.flagFilterInput : '');
  const [debouncedValue, setDebouncedValue] = useState('');

  const searchValue = useSelector(state => state.dashboardFilters.filtersInputs?.[props.objectName]?.value || '');
  useEffect(() => {
    if (searchValue) {
      setInputValue(searchValue);
    }
  }, [searchValue]);

  // Debounce input value
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(inputValue);
    }, 500); // Adjust debounce time as needed

    return () => {
      clearTimeout(handler);
    };
  }, [inputValue]);

  // Call setFilters whenever debouncedValue changes
  useEffect(() => {
    dispatch(setFilters(defaultFilterPayload(props.objectName, field, debouncedValue, "search", props.sourceTable, props.additionalTables)))
  }, [debouncedValue]);

  // console.log("FDSearchV2", props.objectName, field);

  return (
    <FormControl sx={{ width: "100%" }}>
      <TextField
        label={props.title}
        id={`search-input-${props.objectName}-${field}`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {inputValue ? (
                <IconButton
                  aria-label="clear search"
                  onClick={() => setInputValue('')}
                  size="small"
                  sx={{ padding: "0px" }}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <SearchIcon />
              )}
            </InputAdornment>
          ),
        }}
        InputLabelProps={{
          shrink: true,  // Force the label to always shrink (stay up)
        }}
        value={inputValue}
        onChange={(event) => setInputValue(event.target.value)}
        size={"small"}
        sx={{										// Allow the select to take up remaining space
          '& .MuiInputBase-root': {
            fontSize: '0.875rem',      	// Reduce the font size
            minHeight: '32px',         	// Set a minimum height
            height: '32px',            	// Set a fixed height
          },
          '& .MuiInputLabel-root': {
            fontSize: '0.875rem',       // Match the input font size
            lineHeight: '1.5',          // Adjust line height to prevent cutoff
            transform: 'translate(14px, 8px) scale(1)', // Adjust label position
          },
          '& .MuiInputLabel-shrink': {
            transform: 'translate(14px, -8px) scale(0.75)', // Adjust shrink position
          },
        }}
      />
    </FormControl>
  )
}

export default FDSearchV2;