import DirectRequest from "./DirectRequest";
import {useDispatch, useSelector} from "react-redux";
import {useState, Fragment, useEffect} from "react";
import {
  getDisplayConfigsArgs, 
  setConfigsReady, 
  setDashboardData, 
  setGetDataError
} from "../../store/dashboardSlice";
import {
  addConfigs,
  dashboardConfigsSelectors,
  updateConfig,
  resetConfig,
  updateConfigTableRows
} from "../../store/dashboardConfigsSlice";
import {setUpSelectionModel} from "../../store/dashboardTableVizSlice";
import * as Constants from "../../Constants";
import {setUpFilters} from "../../store/dashboardFiltersSlice";
import {setUpSortRules} from "../../store/dashboardSortSlice";
import {setUpTransforms} from "../../store/dashboardTransformSlice";

const CheckForReportRequest = ({ tempDashboardData, setTempDashboardData, setDisplayConfigsArgs, companyUuid, dashboardUuid, handleDashboardDataError}) => {
  const [listERPReportsArgs, setListERPReportsArgs] = useState(null);
  const [getERPReportArgs, setGetERPReportArgs] = useState(null);
  
  useEffect(() => {
    if (tempDashboardData && tempDashboardData.entryPeriod) {
      const tempListArgs = {
        url: companyUuid ? Constants.SERVER_SYSADMIN_LIST_ERP_REPORTS_URL + companyUuid : Constants.SERVER_LIST_ERP_REPORTS_URL,
        method: "POST",
        body: JSON.stringify({
          reportName: tempDashboardData.reportName,
          includeReportData: false
        })
      }
      setListERPReportsArgs(tempListArgs);
    }
  }, [tempDashboardData, companyUuid, dashboardUuid])

  const handleERPReports = (res) => {
    console.log("ERP Reports res", res);
    // what shape is this? is res (data) an array?
    const matchingERPReport = res.file.find((report) => report.entryPeriod === tempDashboardData.entryPeriod);
    if (matchingERPReport) {
      // get report data 
      const tempReportArgs = {
        url: companyUuid ? Constants.SERVER_SYSADMIN_GET_ERP_REPORT_URL + companyUuid : Constants.SERVER_GET_ERP_REPORT_URL,
        method: "POST",
        body: JSON.stringify({
          erpReportUuid: matchingERPReport.uuid
        })
      }
      setGetERPReportArgs(tempReportArgs);
      setListERPReportsArgs(null);
    } else {
      // get and set tempDisplayConfigsArgs
      let entryPeriod = null;
      Object.entries(tempDashboardData.dashboardJson.components).forEach(([objectName, component]) => {
        if (entryPeriod) return;
        if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
          entryPeriod = component.createEntryConfig.entryPeriod;
        } else if (component.overwriteColumnConfig && component.overwriteColumnConfig.displayConfigEntryPeriod) {
          entryPeriod = component.overwriteColumnConfig.displayConfigEntryPeriod;
        }
      })
      setDisplayConfigsArgs(getDisplayConfigsArgs(tempDashboardData.dashboardJson, dashboardUuid, companyUuid, entryPeriod))
      setListERPReportsArgs(null);
    }
  }

  const handleERPReport = (res) => {
    console.log("ERP Report res", res);
    const appData = res.file.appData;
    const dashboardJson = JSON.parse(appData.dashboardJson);
    const tables = appData.tables;
    let entryPeriod = null;
    Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
      if (entryPeriod) return;
      if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
        entryPeriod = component.createEntryConfig.entryPeriod;
      } else if (component.overwriteColumnConfig && component.overwriteColumnConfig.displayConfigEntryPeriod) {
        entryPeriod = component.overwriteColumnConfig.displayConfigEntryPeriod;
      }
    })
    const tempDisplayConfigsArgs = getDisplayConfigsArgs(dashboardJson, dashboardUuid, companyUuid, entryPeriod);
    setTempDashboardData({
      dashboardJson: dashboardJson,
      tables: tables,
      isReport: true
    })
    setDisplayConfigsArgs(tempDisplayConfigsArgs)
    setGetERPReportArgs(null);
  }

  return (
    <>
      <DirectRequest
      // list erp reports
        requestArgs={listERPReportsArgs}
        afterProcess={handleERPReports}
        handleError={err => handleDashboardDataError(err)}
        handleCatchError={err => handleDashboardDataError(err)}
      />
      <DirectRequest
      // get erp report if matching found
        requestArgs={getERPReportArgs}
        afterProcess={handleERPReport}
        handleError={err => handleDashboardDataError(err)}
        handleCatchError={err => handleDashboardDataError(err)}
      />
    </>
  )
}

export const GetDashboardAndConfigsRequest = () => {
  const dispatch = useDispatch();
  const getDataArgs = useSelector(state => state.dashboard.getDataArgs);
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  const dashboardUuid = useSelector(state => state.dashboard.uuid);

  const [tempDashboardData, setTempDashboardData] = useState(null);

  const [displayConfigsArgs, setDisplayConfigsArgs] = useState([]);
  const [displayConfigs, setDisplayConfigs] = useState([]);

  const resetLocalState = () => {
    setTempDashboardData(null);
    setDisplayConfigsArgs([]);
    setDisplayConfigs([]);
  }

  const handleDashboardData = (res) => {
    const dashboardJson = JSON.parse(res.dashboardJson);
    const tables = res.tables;
    // find entryPeriod from dashboardJson - will be sent w/ displayConfigArgs - it will have the same value for each component in the dashboardJson, so just get it once
    let entryPeriod = null;
    let entryPeriodName = "";
    let reportName = "";
    Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
      if (entryPeriod) return;
      if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
        entryPeriod = component.createEntryConfig.entryPeriod;
        entryPeriodName = component.createEntryConfig.entryPeriodName;
        reportName = component.createEntryConfig.reportName;
      } else if (component.overwriteColumnConfig && component.overwriteColumnConfig.displayConfigEntryPeriod) {
        entryPeriod = component.overwriteColumnConfig.displayConfigEntryPeriod;
      }
    })

    // find each component with displayConfigEnabled
    const tempDisplayConfigsArgs = getDisplayConfigsArgs(dashboardJson, dashboardUuid, companyUuid, entryPeriod);
    if (tempDisplayConfigsArgs.length === 0) {
      console.log("no display configs for app" + res.appName);
      dispatch(setDashboardData({
        dashboardJson: dashboardJson,
        tables: tables
      }));

      dispatch(setUpTransforms({ tables: tables, dashboardJson: dashboardJson }))
      dispatch(setUpFilters({ tables: tables }));
      dispatch(setUpSortRules({ dashboardJson: dashboardJson }));
      dispatch(setUpSelectionModel({ dashboardJson: dashboardJson }));

      dispatch(setConfigsReady());
      resetLocalState();
    } else {
      /* 
      If entryPeriod - need to check if past report 
      - if past report - set that dashboard to tempDashboardData and get tempDisplayConfigsArgs from it
      - if no past report - use tempDashboardData and get tempDisplayConfigsArgs from it
      */
      if (entryPeriod) {
        setTempDashboardData({
          dashboardJson: dashboardJson,
          tables: tables,
          entryPeriod: entryPeriod,
          reportName: reportName
        })
      } else {
        setTempDashboardData({
          dashboardJson: dashboardJson,
          tables: tables
        })
        setDisplayConfigsArgs(tempDisplayConfigsArgs);
      }
    }
    console.warn("layout", JSON.parse(res.dashboardJson));
    console.warn("tables", res.tables);
  }

  const handleDashboardDataError = (err) => {
    console.log("getData error", err);
    console.log("getData args", getDataArgs);
    dispatch(setGetDataError("There was an error retrieving your dashboard"))
  }

  const handleDisplayConfig = (res) => {
    console.log("handleDisplayConfig res:", res);
    if (displayConfigsArgs.length === 1) {
      console.log("displayConfigsArgs.length === 1")
      // last displayConfig - dispatch each in displayConfigs and current res to dashboardConfigSlice
      // wipe local state and dispatch tempDashboardSTate to dashboardSlice
      const sourceTable = JSON.parse(displayConfigsArgs[0].body).sourceTableName;
      const displayConfigWithSourceTable = {
        ...res.displayConfigTable,
        sourceTable: sourceTable
      }

      const allConfigs = [...displayConfigs, displayConfigWithSourceTable];
      const dashboardData = {...tempDashboardData}
      allConfigs.forEach((displayConfig) => {
        /*
        String uuid;
        String companyUuid;
        String createdBy;
        String developerAppUuid;
        String objectName;
        List<String> primaryKeyFieldList;
        List<DisplayConfigTableRowDTO> displayConfigTableRows;
        Map<String, List<String>> linkedTableForeignFieldMap;
         */
        // give the component its config uuid so it's actions buttons know which config to update
        // and its table knows which config displayConfigTableRows to monitor
        dashboardData['dashboardJson']['components'][displayConfig.objectName]['displayConfigUuid'] = displayConfig.uuid;
      })
      // console.log("dashboardData", dashboardData)

      //const allConfigs = [...displayConfigs, displayConfigWithSourceTable];
      dispatch(addConfigs(allConfigs));
      dispatch(setDashboardData(dashboardData));

      dispatch(setUpTransforms(dashboardData));
      dispatch(setUpFilters(dashboardData));
      dispatch(setUpSortRules(dashboardData));
      dispatch(setUpSelectionModel(dashboardData));

      dispatch(setConfigsReady());
      // wipe local state
      resetLocalState();
    } else {
      // push the res into displayConfigs - slice displayConfigsArgs
      // add back sourceTableName to the config
      const sourceTable = JSON.parse(displayConfigsArgs[0].body).sourceTableName;
      const displayConfigWithSourceTable = {
        ...res.displayConfigTable,
        sourceTable: sourceTable
      }
      setDisplayConfigs((prevState) => [...prevState, displayConfigWithSourceTable]);
      setDisplayConfigsArgs((prevState) => prevState.slice(1));
    }
  }

  return (
    <>
      <DirectRequest
        requestArgs={getDataArgs}
        afterProcess={handleDashboardData}
        handleError={handleDashboardDataError}// TODO: setGetDataError
        handleCatchError={handleDashboardDataError}
      />
      {/* check for report dashboard for this entryPeriod and replace tempDashboardData */}
      <CheckForReportRequest
        tempDashboardData={tempDashboardData}
        setTempDashboardData={setTempDashboardData}
        setDisplayConfigsArgs={setDisplayConfigsArgs}
        companyUuid={companyUuid}
        dashboardUuid={dashboardUuid}
        handleDashboardDataError={handleDashboardDataError}
      />
      {/* need request for erp report */}
      {displayConfigsArgs.length > 0 && (
        <DirectRequest
          requestArgs={displayConfigsArgs[0]}
          afterProcess={handleDisplayConfig}
          handleError={() => {}}// TODO: how to handle error here?
          handleCatchError={() => {}}
        />
      )}
    </>
  )
}

const GetReportAndConfigsRequest = () => {
  // we need to get the old report via getReportDataArgs in dashboardSlice
  // then we need to copy the displayconfigsargs process from GetDashboardAndConfigsRequest for the report's displayConfigs
  const dispatch = useDispatch();
  const companyUuid = useSelector(state => state.dashboard.companyUuid);
  const dashboardUuid = useSelector(state => state.dashboard.uuid);

  const getReportDataArgs = useSelector(state => state.dashboard.getReportDataArgs);
  const [tempDashboardData, setTempDashboardData] = useState(null);

  const [displayConfigsArgs, setDisplayConfigsArgs] = useState([]);
  const [displayConfigs, setDisplayConfigs] = useState([]);

  const resetLocalState = () => {
    setTempDashboardData(null);
    setDisplayConfigsArgs([]);
    setDisplayConfigs([]);
  }

  const handleReportData = (res) => {
    // figure out where appData is
    console.log("ERP Report res", res);
    const appData = res.file.appData
    const dashboardJson = JSON.parse(appData.dashboardJson);
    const tables = appData.tables;
    // set to tempDashboardData w/ isReport: true
    // generate displayConfigsArgs
    let entryPeriod = null;
    Object.entries(dashboardJson.components).forEach(([objectName, component]) => {
      if (entryPeriod) return;
      if (component.createEntryConfig && component.createEntryConfig.entryPeriod) {
        entryPeriod = component.createEntryConfig.entryPeriod;
      }
    })
    //getDisplayConfigsArgs = (dashboardJson, dashboardUuid, companyUuid, entryPeriod)
    const displayConfigsArgs = getDisplayConfigsArgs(dashboardJson, dashboardUuid, companyUuid, entryPeriod);
    setTempDashboardData({
      dashboardJson: dashboardJson,
      tables: tables,
      isReport: true
    })
    setDisplayConfigsArgs(displayConfigsArgs);
  }

  const handleDashboardDataError = (err) => {
    console.log("getData error", err);
    console.log("getData args", getReportDataArgs);
    dispatch(setGetDataError("There was an error retrieving your dashboard"))
  }

  const handleDisplayConfig = (res) => {
    console.log("handleDisplayConfig res:", res);
    if (displayConfigsArgs.length === 1) {
      console.log("displayConfigsArgs.length === 1")
      // last displayConfig - dispatch each in displayConfigs and current res to dashboardConfigSlice
      // wipe local state and dispatch tempDashboardSTate to dashboardSlice
      const sourceTable = JSON.parse(displayConfigsArgs[0].body).sourceTableName;
      const displayConfigWithSourceTable = {
        ...res.displayConfigTable,
        sourceTable: sourceTable
      }

      const allConfigs = [...displayConfigs, displayConfigWithSourceTable];
      const dashboardData = {...tempDashboardData}
      // add displayConfigUuids to dashboardJson components
      allConfigs.forEach((displayConfig) => {
        dashboardData['dashboardJson']['components'][displayConfig.objectName]['displayConfigUuid'] = displayConfig.uuid;
      })
      // console.log("dashboardData", dashboardData)

      dispatch(addConfigs(allConfigs));
      dispatch(setDashboardData(dashboardData));

      dispatch(setUpTransforms(dashboardData));
      dispatch(setUpFilters(dashboardData));
      dispatch(setUpSortRules(dashboardData));
      dispatch(setUpSelectionModel(dashboardData));

      dispatch(setConfigsReady());
      // wipe local state
      resetLocalState();
    } else {
      // push the res into displayConfigs - slice displayConfigsArgs
      // add back sourceTableName to the config
      const sourceTable = JSON.parse(displayConfigsArgs[0].body).sourceTableName;
      const displayConfigWithSourceTable = {
        ...res.displayConfigTable,
        sourceTable: sourceTable
      }
      setDisplayConfigs((prevState) => [...prevState, displayConfigWithSourceTable]);
      setDisplayConfigsArgs((prevState) => prevState.slice(1));
    }
  }
  
  return (
    <>
      <DirectRequest
        requestArgs={getReportDataArgs}
        afterProcess={handleReportData}
        handleError={handleDashboardDataError}
        handleCatchError={handleDashboardDataError}
      />
      {displayConfigsArgs.length > 0 && (
        <DirectRequest
          requestArgs={displayConfigsArgs[0]}
          afterProcess={handleDisplayConfig}
          handleError={() => {}}// TODO: how to handle error here?
          handleCatchError={() => {}}
        />
      )}
    </>
  )
}

const DashboardConfigUpdateHandler = ({ configUuid }) => {
  const dispatch = useDispatch();
  const configUpdateArgs = useSelector((state) => dashboardConfigsSelectors.selectById(state, configUuid).updateArgs);

  // const handleConfigUpdate = (res) => {
  //   console.log(configUuid + " displayConfig update res", res)
  //   // will get whole config back - need to update config displayConfigTableRows - no need for refresh
  //   const newDisplayConfigTableRows = res.displayConfigTable.displayConfigTableRows || [];
  //   dispatch(updateConfigTableRows(configUuid, newDisplayConfigTableRows));
  // }
  const handleConfigUpdate = (res) => {
    // MODIFY THIS TO FOLLOW THE RESET REQUEST PATTERN
    console.log(configUuid + " displayConfig update res", res)
    if (configUpdateArgs.length === 1) {
      console.log("all config updates processed for config ", configUuid)
      // will get whole config back - need to update config displayConfigTableRows - no need for refresh
      const newDisplayConfigTableRows = res.displayConfigTable.displayConfigTableRows || [];
      dispatch(updateConfigTableRows(configUuid, newDisplayConfigTableRows));
    } else {
      dispatch(updateConfig(configUuid, configUpdateArgs.slice(1)))
    }
    
  }

  return configUpdateArgs && (
    <DirectRequest
      requestArgs={configUpdateArgs[0]}
      afterProcess={handleConfigUpdate}
      handleError={() => {}}// TODO: how to handle error here?
      handleCatchError={() => {}}
    />
  )
}

const DashboardConfigResetHandler = ({ configUuid }) => {
  const dispatch = useDispatch();
  // - each handler needs to watch for its displayConfig's resetArgs - needs to fire the request - once all resets fired get config table rows - pass them to config
  const configResetArgs = useSelector((state) => dashboardConfigsSelectors.selectById(state, configUuid).resetArgs);
  const handleConfigRowReset = (res) => {
    // check if last reset
    if (configResetArgs.length === 1) {
      console.log("all displayConfigTableRows reset for displayConfig", configUuid);
      // get res.displayConfig
      const newDisplayConfigTableRows = res.displayConfigTable.displayConfigTableRows || [];
      dispatch(updateConfigTableRows(configUuid, newDisplayConfigTableRows));
    } else {
      // resetArgs = resetArgs.slice(1);
      dispatch(resetConfig(configUuid, configResetArgs.slice(1)));
    }
  }
  return configResetArgs ? (
    <DirectRequest
      requestArgs={configResetArgs[0]}
      afterProcess={handleConfigRowReset}
      handleError={() => {}}// TODO: how to handle error here?
      handleCatchError={() => {}}
    />
  ) : null

}

const DashboardConfigsUpdateRequest = () => {
  // needs to map over display configs and spawn a handler for each
  // - each handler needs to watch for its displayConfig's updateArgs - needs to fire the request - needs to get updated config - needs to pass them to config
  const displayConfigs = useSelector((state) => dashboardConfigsSelectors.selectIds(state));
  return (
    <>
      {displayConfigs.map(configUuid => {
        return (
          <Fragment key={configUuid + "_update_request"}>
            <DashboardConfigUpdateHandler key={configUuid + "_update_handler"} configUuid={configUuid} />
            <DashboardConfigResetHandler key={configUuid + "_reset_handler"} configUuid={configUuid} />
          </Fragment>
        )
      })}
    </>
  )
}

export const DashboardRequest = () => {
  return (
    <>
      <GetDashboardAndConfigsRequest/>
      <GetReportAndConfigsRequest/>
      <DashboardConfigsUpdateRequest/>
    </>
  )
}

export default DashboardRequest;